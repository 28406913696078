<template>
  <div class="inside-page-content">
    <div class="paper" style="background-color: #F7F9FE;">
      <div class="notice-title h1">{{ detailData.expertName }}</div>
      <div class="guanjiancitiao">关键词条</div>
      <div class="h4 time">{{ detailData.title }}</div>
      <div style="background-color: #fff;line-height: 50px;">
        <div style="padding: 20px 30px;min-height: 300px;">
          <img
            :src="detailData.photoUrl"
            style="width: 50%;height: 300px;object-fit: contain;float: right;"
          />
          <p>{{ detailData.introduction }}</p>
        </div>
        <blue-tag title="人物经历">
          <div>{{ detailData. experience}}</div>
        </blue-tag>
        <blue-tag title="主要成就">
          <div>{{ detailData. majorAchievements}}</div>
        </blue-tag>
      </div>
    </div>
  </div>
</template>

<script>
import blueTag from './components/blue-tag-section-title.vue'

export default {
  name: 'Home',
  components: {
    blueTag
  },
  data() {
    return {
      notice_id: null,
      detailData: {}
    }
  },
  computed: {
  },
  created(options) {
    console.log('options = ', options)
    console.log('this.$route.query = ', this.$route.query)
    const notice_id = this.$route.query?.id
    if (notice_id) {
      this.notice_id = notice_id
      this.$api.solicitationCloud.expertDetail({ 'id': this.notice_id }).then((res) => {
        const data = res.data.data
        if (data) {
          this.detailData = data;
        }
      })
    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style scoped lang="less">
.notice-title {
  margin-top: 35px;
  font-weight: 700;
  display: inline-block;
  margin-right: 20px;
}

.guanjiancitiao {
  display: inline-block;
  width: 100px;
  height: 25px;
  background-image: url("../../static/images/guanjiancitiao.png");
  background-size: 100% 100%;
  line-height: 25px;
  padding: 0 20px;
  color: #fff;
}

.time {
  // text-align: center;
  margin: 10px 0;
  padding-bottom: 30px;
  border-bottom: 2px dashed #cccccc;
}
</style>
